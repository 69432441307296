<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>
                <router-link :to="{ name: 'company.faq.list' }">
                  {{ $t("faq.management") }}
                </router-link>
              </li>
              <li>{{ $t("faq.edit") }}</li>
            </ul>
          </nav>
        </div>
        <div class="card" v-if="is_loading">
          <default-loading></default-loading>
        </div>
        <template v-else>
          <template v-if="errorMessages">
            <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p style="color: red">
                <i class="uil-exclamation-triangle"></i> {{ error }}
              </p>
            </div>
          </template>
          <div v-if="successMessage" class="uk-alert-success" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: green">
              <i class="uil-exclamation-triangle"></i> {{ successMessage }}
            </p>
          </div>
          <div class="card mt-4">
            <div class="card-body">
              <h4><i class="uil-faq"></i> {{ $t("faq.edit") }}</h4>
              <hr />
              <div class="row">
                <div class="col-xl-9 m-auto">
                  <form action="">
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="name">{{ $t("faq.title") }} <span
                          class="required">*</span></label>
                      <div class="col-md-9">
                        <input type="text" class="form-control" id="name" name="name" value=""
                          :placeholder="$t('faq.enter_name')" required="" v-model="form.title" />
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="short_description">{{
                        $t("general.description")
                      }}</label>
                      <div class="col-md-9">
                        <vue-editor v-model="form.description"></vue-editor>

                        <!-- <textarea name="short_description" id="short_description" class="form-control"
                          v-model="form.description"></textarea> -->
                      </div>
                    </div>

                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="status">
                        {{ $t("general.status") }}</label>
                      <div class="col-md-9">
                        <toggle-button :width="80" v-model="form.is_status" color="#ae71ff" :sync="true" :labels="{
                          checked: $t('general.active'),
                          unchecked: $t('general.passive'),
                        }" />
                      </div>
                    </div>
                    <div class="form-group row mb-3">
                      <label class="col-md-3 col-form-label" for="category">
                        {{ $t("faq.category") }} <span class="required">*</span></label>
                      <div class="col-md-9">
                        <multiple-select-input :model.sync="form.category" :required="false" :searchable="true"
                          :multiple="false" :allow-empty="false" :values="categories"
                          @select="form.category = $event.id" />
                      </div>
                    </div>

                    <div class="uk-card-footer float-right pb-0 pr-0">
                      <router-link class="uk-button-default mr-2 uk-button small" :to="{ name: 'company.faq.list' }">
                        {{ $t("general.back") }}
                      </router-link>
                      <button class="uk-button uk-button-primary small" type="button" @click="saveForm">
                        <i class="icon-feather-arrow-right"></i>
                        {{ $t("general.save") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import axios from "axios";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import { VueEditor } from "vue2-editor";
import faq_module, {
  BASE_URL,
  MODULE_NAME,
  ERROR,
  ITEM,
  GET_ITEM,
  CREATE_ITEM,
  UPDATE_ITEM,
} from "@/core/services/store/faq.module";
export default {
  name: "FaqEdit",
  components: { MultipleSelectInput, DataTable, DefaultLoading, VueEditor },
  data() {
    return {
      is_loading: false,
      successMessage: null,
      errorMessages: [],
      is_status: false,
      is_address: false,
      image_updated: false,
      categories: [],
      form: {
        is_status: false,
        title: "",
        description: "",
        category: {
          id: null,
          name: null
        },
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, faq_module);
  },
  computed: {
    item: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEM];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    getCategories() {
      let self = this;
      axios
        .get(process.env.VUE_APP_BACKEND_APP_URL + "/api/faq-categories", {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
            "Content-Type": "application/json",
            Authorization: "Bearer " + this.secureLocalStorage("token"),
          },
        })
        .then((response) => {
          response.data.data.forEach((currentItem) => {
            self.categories.push({
              name: currentItem.description.title,
              id: currentItem.id,
            });
          });
        });
    },
    getItem() {
      let self = this
      store
        .dispatch(MODULE_NAME + "/" + GET_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id,
        })
        .then(() => {
          self.form.title = self.item.description.title;
          self.form.description = self.item.description.description;
          self.form.category.id = self.item.category.id;
          self.form.category.name = self.item.category.description.title;
          self.form.is_status = self.item.status ? true : false;
        });
    },
    saveForm() {
      let form = this.form;
      let formData = new FormData();
      this.errorMessages = [];
      this.is_loading = true;
      if (form.title.length === 0) {
        this.errorMessages.push(
          this.$t("validation.required_field").replace(":NAME", this.$t("faq.title"))
        );
        this.is_loading = false;
        return false;
      }
      formData.append("title", form.title);
      formData.append("description", form.description);
      formData.append("category_id", form.category.id);
      formData.append("status", form.is_status == true ? 1 : 0);
      store
        .dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
          url: BASE_URL + "/" + this.$route.params.id + "/update",
          contents: formData,
          returnType: "stateless",
          acceptPromise: true,
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_updated");
            //this.$router.push({ name: "company.faq.list" });
          } else this.errorMessages.push(this.error);
          this.is_loading = false;
        });
    },
    processFile(event) {
      this.image_updated = true;
      this.item.image = event.target.files[0];
      let blob = URL.createObjectURL(this.item.image);
      let imageHolder = $(".logoarea");
      imageHolder.attr("src", blob);
    },
    inputClick() {
      $("#avatar").click();
    },
    resetMessages() {
      this.errorMessages = [];
      this.successMessage = null;
    },
  },
  mounted() {
    this.getItem();
    this.getCategories();
  },
  watch: {
    error() {
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
